@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./variables.scss";
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #F9F9FC;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  font: 400 16px/26px "Poppins", sans-serif;
  overflow-x: clip;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #19191D;
  // zoom: 0.8;
}
p { margin: 0 0 10px;}
#layout_wrapper {
  overflow: hidden;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  line-height: 1.17;
  margin: 0 0 10px;
  color: #19191D;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.img-responsive {
  width: 100%;
  height: auto;
  display: block;
}
.pre-word-line {
  white-space: pre-line;
}
.list-none {
  margin: 0;
  padding: 0;
  list-style: none;
}
.word-break {
  word-break: break-word;
}
.d-block {
  display: block;
}
.no-padding {
  padding: 0 !important;
}
.is-scrollable {
  overflow: hidden;
}
input[type="submit"],
button {
  cursor: pointer;
}
img {
  vertical-align: top;
  border-style: none;
}
*:focus {
  outline: none;
}
.transition {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}
.w-100 {
  width: 100% !important;
}
.error-msg {
  color: red;
  margin-top: 15px;
  display: block;
}
.error-msg:first-letter {
  text-transform: capitalize;
}
#wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.container {
  max-width: 1270px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  z-index: 1;
  width: 100%;
}
.container.fluid {
  width: 100%;
  max-width: none;
}
.container:after {
  display: block;
  clear: both;
  content: "";
}
#main {
  width: 100%;
  min-height: calc(100vh - 110px);
  padding: 0 0 50px;
}
.required-field {
  color: #db0000;
  font-style: normal;
  margin-left: 5px;
  font-size: 17px;
}
a {
  text-decoration: none;
  color: $white_color;
}
.pointer {
  cursor: pointer;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.direction-column {
  flex-direction: column;
}
.direction-row {
  flex-direction: row;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.items-center {
  align-items: center;
}
.text-center {
  text-align: center;
  position: relative;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.flex-text-center {
  justify-content: center;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.m-0 {
  margin: 0;
}
.btn-faded {
  opacity: 0.5;
}
.flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
input,
button,
textarea {
  font: 400 16px/20px "Poppins", sans-serif;
}
input,
textarea,
select {
  width: 100%;
  box-shadow: 4px 4px 20px rgba(0,0,0,0.08);
  border-radius: 4px;
  border: 1px solid #C4C4C4;
  background-color: #fff;
  height: 50px;
  padding-left: 20px;
  padding-right: 30px;
  color: #201E1F;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  &::placeholder { color: #201E1F;}
}
textarea {
  height: 188px;
  padding-top: 20px;
  @media (max-width: $breakpoint_laptop) {
    height: 120px;
  }
}
button:focus,
textarea:focus,
input:focus,
select,
textarea {
  outline: none;
}
textarea::placeholder,
input::placeholder {
  color: #201E1F;
}
textarea,
input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="string"],
select {
  color: #201E1F;
}
input[type="date"] {
  text-transform: uppercase;
  background: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
select {
  outline: none;
}
.table {
  width: 100%;
  border: none;
  border-collapse: collapse;
}
.btn-green {
  color: white;
  background-color: #6bb64a;
  border-radius: 10px;
  border: 1px solid #6bb64a;
  padding: 10px 22px;
  font-size: 14px;
  @media (max-width: $breakpoint_laptop) {
    padding: 7px 20px;
    font-size: 13px;
  }
  &:hover {
    background-color: white;
    color: #6bb64a;
    border: 1px solid #6bb64a;
  }
}
.contact_margin {
  margin-right: 18px;
}
.grid-container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
.grid-container-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 29px;
}
.grid-container-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 39px;
  @media (max-width: $breakpoint_tablet) {
    gap: 15px;
  }
  @media screen and (max-width: $breakpoint_mobile_landscape) {
    grid-template-columns: 1fr 1fr;
  }
}
.grid-container-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  @media screen and (max-width: $breakpoint_tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: $breakpoint_mobile_portrait) {
    grid-template-columns: 1fr;
  }
}

.globle_wrapper {
  overflow: hidden;
  position: relative;
}
.simple_btn {
  display: inline-block;
  color: #000;
  font-weight: 600;
  border-radius: 8px;
  padding: 12px 32px;
  border: none;
  line-height: 24px;
  background-color: #fff;
  box-shadow: inset 3px 6px 7px rgba(255, 255, 255, 0.23);
  transition: all 0.4s ease-in-out;
  &:hover {
    transform: scale(0.97);
  }
  &.black {
    background-color: #000000;
    color: #FCFCFC;
  }
}
.map_wrap {
  padding: 125px 0 0;
  @media (max-width: $breakpoint_tablet) {
    padding: 60px 0 0;
  }
  img {
    width: 100%;
    height: auto;
  }
}
#root { overflow: hidden;}
.user_input {
  border-radius: 8px;
  color: #F9F9FC !important;
  height: 64px;
  font-size: 16px;
  background-color: rgba(25,25,29,0.50);
  box-shadow: inset 0 0 30px rgba(249,249,252,0.50);
  padding-left: 20px;
  &::placeholder {color: #F9F9FC;}
}
abbr[title] {
  text-decoration: none;
}
// admin_wrapper
.admin_wrapper {
  display: flex;
  justify-content: flex-end;
  .admin_content_wrapper {
    width: calc(100% - 320px);
    min-height: 100vh;
    @media (max-width: $breakpoint_tablet) {
      width: calc(100%);
    }
    .container {
      max-width: 1234px;
    }
    .logout {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 40px 50px 0;
      @media (max-width: $breakpoint_tablet) {
        padding: 15px 15px 0;
      }
      a {
        border-radius: 8px;
        background: #3F3F4E;
        padding: 18px 40px;
        display: inline-block;
        @media (max-width: $breakpoint_tablet) {
          padding: 12px 30px;
        }
      }
    }
    .inner_content_wrap {
      padding: 0 20px 50px;
      @media (max-width: $breakpoint_tablet) {
        padding: 0 0 30px;
        zoom: 0.8;
      }
    }
  }
}
.simple_search_btn {
  border-radius: 8px;
  background: #3F3F4E;
  padding: 18px 40px;
  display: inline-block;
  color: #F9F9FC;
  border: none;
}
.react-datepicker {
  background-color: #F9F9FC !important;
  border-color: #DBDBDB !important;
  width: 361px;
  padding-bottom: 15px;
  z-index: 9999;
  @media (max-width: $breakpoint_tablet) {
    width: 320px;
  }
  
  .react-datepicker__navigation { top: 25px;}
  .react-datepicker__day--highlighted { background-color: #3F3F4E; color: #fff;}
  .react-datepicker__day {
    border-radius: 100%;
  }
  .react-datepicker__month-container {
    width: 100%;
    .react-datepicker__header {
      background-color: transparent;
      border-bottom: none;
    }
    .react-datepicker__current-month {
      font-size: 21px;
      font-weight: 500;
      margin-bottom: 24px;
      margin-top: 20px;
    }
    .react-datepicker__day-names,.react-datepicker__week {
      display: flex;
      justify-content: space-around;
    }
  }
}


.header_wrap {
  @media (max-width: 1600px) {
    zoom: 0.8;
  }
}
.footer_wrap,.inner_wrapper,.modal-dialog,.contact_inner_wrapper,.gift_links,.gift_content,.book_content,
.box_wrap {
  @media (min-width: 1200px) and (max-width: 1600px) {
    zoom: 0.7;
  }
  @media (max-width: $breakpoint_tablet) {
    zoom: 0.8
  }
}
.inner_content_wrap,.logout,.request_form_wrapper .modal-dialog {
  @media (min-width: 1200px) and (max-width: 1600px) {
    zoom: 0.78;
  }
}

.fade_effect {
  opacity: 0;
  transition: opacity 3s;
  &.fade-enter,
  &.fade-enter-done {
      opacity: 1;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}


.typing-effect {
  animation: blink-caret .75s step-end infinite;
}

@keyframes blink-caret {
  0% {
      opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.soon_wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  h1 {
    font-weight: bold;
    font-size: 40px;
  }
}
.react-calendar__navigation__arrow { display: none !important;}
.react-calendar__navigation__label,.react-calendar__month-view__days__day:disabled,
.react-calendar__month-view__days__day--neighboringMonth {pointer-events: none !important; opacity: 0.24;}
.w-100 { width: 100% !important;}
.react-calendar__navigation .react-calendar__navigation__label { opacity: 1 !important;}
.error-message {
  &.red_color {
    color: #fff;
    background: rgba(255, 0, 0, 0.8);
    padding: 4px 0;
    margin: 5px 0 0;
    border-radius: 5px;
  }
}
.react-datepicker__day--today:hover { color: #fff}
.availability_input {
  .react-datepicker__day--today {
    color: #BBB;
    background: transparent;
    pointer-events: none;
    border: 1px solid #000;
  }
}



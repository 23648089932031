@import "../../../assets/styles/variables.scss";
.dashboard_wrapper {
    padding: 40px 30px;
    @media (max-width: $breakpoint_tablet) {
        padding: 30px 0;
    }
    .dashboard_boxes {
        margin-bottom: 60px;
        @media (max-width: $breakpoint_tablet) {
            margin-bottom: 30px;
        }
        ul {
            li {
                @media (max-width: $breakpoint_tablet) {
                    display: flex;
                    justify-content: center;
                }
                section {
                    background: url('../../../assets/images/card_box.svg') no-repeat center;
                    background-size: 100% 100%;
                    text-align: center;
                    box-shadow: 48px 58px 88px rgba(0,0,0,0.1);
                    padding: 57px 0 57px 11px;
                    border-radius: 15px;
                    @media (max-width: $breakpoint_tablet) {
                        padding: 20px 17px 20px 25px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 160px;
                        height: 124px;
                    }
                    h2 {
                        margin: 0;
                        font-size: 60px;
                        font-weight: bold;
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 36px;
                        }
                    }
                    span {
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
    .second_section {
        ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 80px;
            @media (max-width: $breakpoint_tablet) {
                grid-template-columns: 1fr;
                gap: 20px;
            }
            li {
                section {
                    width: 100%;
                    background: linear-gradient(135deg, rgba(77,77,84,1) 0%, rgba(25,25,29,1) 100%);
                    padding: 32px 35px;
                    border-radius: 16px;
                    min-height: 173px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    @media (max-width: $breakpoint_tablet) {
                        padding: 25px;
                        min-height: auto;
                    }
                    h2 {
                        margin-bottom: 26px;
                        font-size: 28px;
                        font-weight: bold;
                        color: #F9F9FC;
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 20px;
                        }
                    }
                    p {
                        display: flex;
                        margin: 0;
                        font-size: 32px;
                        color: #F9F9FC;
                        align-items: center;
                        justify-content: space-between;
                        img {
                            cursor: pointer;
                            transition: all 0.4s ease-in-out;
                            &:hover { transform: scale(0.9);}
                        }
                        input {
                            width: 250px;
                            background: transparent;
                            color: #F9F9FC;
                            &::placeholder {
                                color: rgba(255,255,255,0.7);
                            }
                        }
                    }
                }
            }
        }
    }
}
.switch_wrap {
    display: flex;
    position: relative;
    height: 43px;
    cursor: pointer;
    input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        &:checked + span {
            &::before {
                background-color: #00FFE2;
                transform: translateX(-100%);
                left: calc(100% - 10px);
            }
        }
    }
    span {
        width: 90px;
        height: 43px;
        border-radius: 64px;
        display: inline-block;
        border: 2px solid #F9F9FC;
        position: absolute;
        &::before {
            content: '';
            position: absolute;
            left: 10px;
            top: 9px;
            width: 22px;
            height: 22px;
            border-radius: 100%;
            background-color: #F9F9FC;
            transition: all 0.2s ease-in-out;
        }
    }
}
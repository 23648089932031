@import "../../assets/styles/variables.scss";
.modal {
    display: block;
    padding: 0 15px;
    overflow: hidden;
    z-index: 1072;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    outline: 0;
    background-color: rgba(249,249,252,0.15);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    @media (max-width: $breakpoint_tablet) {
        overflow-y: auto;
    }
    &.download_modalWrap {
        .modal-dialog {
            max-width: 700px;
        }
    }
    &.del_modal {
        .modal-dialog {
            max-width: 700px;
            zoom: 1;
            .modal-content {
                .first_modal {
                    text-align: center;
                    h2 {
                        color: #F9F9FC;
                        margin: 0 0 20px;
                        font-size: 32px;
                        font-weight: bold;
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 30px;
                        }
                    }
                    p {
                        margin-bottom: 30px;
                        font-size: 24px;
                        color: #F9F9FC;
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 20px;
                        }
                    }
                    .button_wrap {
                        display: flex;
                        gap: 20px;
                        .request_modal { margin-top: 0;}
                    }
                }
            }
        }
    }
    &.gift_modal_wrap {
        .request_modal { margin-top: 40px;}
        .modal-dialog {
            .modal-content {
                .first_modal {
                    padding-left: 30px;
                    padding-right: 30px;
                    text-align: center;
                    h2 {
                        font-size: 48px;
                        margin-bottom: 40px;
                        line-height: 48px;
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 34px;
                            margin-bottom: 25px;
                        }
                    }
                    p {
                        font-size: 24px; margin: 0;
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    &.contact_schedule_wrap {
        .modal-dialog {
            max-width: 1170px;
            .modal-content {
                background: url('../../assets/images/contact_modal_bg.jpg') center;
                background-size: cover;
                backdrop-filter: unset;
                padding: 60px;
                @media (max-width: $breakpoint_tablet) {
                    padding: 20px;
                }
            }
        }
    }
    &.request_form_wrapper {
        .request_modal { margin-top: 30px;}
        .modal-dialog {
            max-width: 100%;
            .modal-content {
                background-color: transparent;
                border-radius: 0;
                backdrop-filter: unset;
            }
        }
    }
    &.request_modal_wrap {
        &.orderplaced_wrap {
            ul {
                li {
                    font-size: 18px;
                    margin: 15px 0;
                    @media (max-width: $breakpoint_mobile_landscape) {
                        margin-bottom: 30px;
                    }
                    span {
                        width: 235px;
                        display: inline-block;
                        margin-right: 20px;
                        @media (max-width: $breakpoint_mobile_landscape) {
                            width: 100%;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            .modal-dialog {
                max-width: 787px;
                .request_modal { margin-top: 45px;}
                .modal-content {
                    .first_modal {
                        padding-top: 80px;
                    }
                }
                h2 {
                    font-size: 48px;
                    margin-bottom: 50px;
                    line-height: 48px;
                    color: #fff;
                    @media (max-width: $breakpoint_tablet) {
                        font-size: 32px;
                        margin-bottom: 30px;
                        line-height: 40px;
                    }
                }
            }
        }
        .modal-dialog {
            max-width: 772px;
            .request_modal { margin-top: 30px;}
            .modal-content {
                .first_modal {
                    padding-top: 90px;
                    padding-bottom: 60px;
                }
            }
            h2 {
                text-align: center;
                margin-bottom: 40px;
                color: #fff;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 18px;
                }
            }
        }    
    }
    .modal-dialog {
        min-height: calc(100% - (1.75rem* 2));
        max-width: 472px;
        margin: 1.75rem auto;
        display: flex;
        align-items: center;
        position: relative;
        width: auto;
        pointer-events: none;
        .modal-content {
            position: relative;
            display: flex;
            -webkit-box-orient: vertical;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: rgba(0,0,0,0.65);
            background-clip: padding-box;
            border-radius: 20px;
            outline: 0;
            -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
            transform: translateY(300px); /* Initially hidden */
            animation: slideIn 0.6s forwards;
            // transform: translateY(200px);
            // opacity: 0.1;
            // transition: all 3s ease-in-out;
            .close {
                position: absolute;
                right: 30px;
                top: 30px;
                background-color: transparent;
                border: none;
                outline: none !important;
                color: #F9F9FC;
                padding: 0;
            }
            .first_modal {
                padding: 71px 60px 67px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @media (max-width: $breakpoint_tablet) {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }
}
.request_modal {
    border: 1px solid #F9F9FC;
    padding: 20px 40px;
    font-size: 24px;
    background-color: rgba(25,25,29,0.50);
    border-radius: 8px;
    color: #F9F9FC;
    margin-top: 57px;
    box-shadow: inset 0 0 30px rgba(249,249,252,0.50);
    transition: all 0.4s ease-in-out;
    @media (max-width: $breakpoint_tablet) {
        padding: 16px 25px;
        font-size: 20px;
    }
    &:hover {
        box-shadow: inset 0 0 30px rgba(249,249,252,0.80);
    }
}
.request_form_wrap {
    display: flex;
    align-items: flex-start;
    width: 100%;
    @media (max-width: $breakpoint_tablet) {
        flex-direction: column;
    }
    form {
        width: 404px;
        margin-right: 100px;
        padding-top: 26px;
        @media (max-width: $breakpoint_tablet) {
            width: 100%;
            padding-top: 0;
        }
        ul {
            display: flex;
            flex-direction: column;
            li {
                margin: 20px 0;
                @media (max-width: $breakpoint_tablet) {
                    margin: 10px;
                }
            }
        }
    }
    .data_from_field {
        width: 588px;
        background: url('../../assets/images/form_bg.jpg') center;
        background-size: cover;
        padding: 26px 60px 60px;
        border-radius: 20px;
        @media (max-width: $breakpoint_tablet) {
            width: 100%;
            margin-top: 10px;
        }
        ul {
            display: flex;
            flex-direction: column;
            li {
                p { margin: 0; display: inline;}
                font-size: 24px;
                display: flex;
                width: 100%;
                height: 64px;
                margin: 20px 0;
                align-items: center;
                color: #19191D;
                word-break: break-word;
                overflow: auto;
                text-overflow: ellipsis;
                @media (max-width: $breakpoint_tablet) {
                    margin: 0;
                }
                &::-webkit-scrollbar {
                    width: 3px;
                    background-color: #ddd;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #19191D;
                }
            }
        }
    }
    .request_mini {
        width: 254px;
        background-color: rgba(25,25,29,0.74);
        margin-top: auto;
        border: 1px solid #707070;
        border-radius: 20px;
        box-shadow: inset 0 0 60px rgba(249,249,252,0.40);
        padding: 29px;
        margin-left: 70px;
        @media (max-width: $breakpoint_tablet) {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
        }
        p {
            margin-bottom: 0;
            line-height: 22px;
            a {
                text-decoration: underline;
                &:hover { color: #00FFE2;}
            }
        }
    }
}


@keyframes slideIn {
    from {
        transform: translateY(300px);
    }
    to {
        transform: translateY(0);
    }
}

.download_btns {
    display: flex;
    gap: 20px;
    @media (max-width: $breakpoint_mobile_landscape) {
        flex-direction: column;
        .request_modal:last-child {
            margin-top: 0;
        }
    }
}
@import "../../assets/styles/variables.scss";
.sidebar_wrap {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 320px;
    background-color: #3F3F4E;
    box-shadow: 6px 0 20px rgba(0,0,0,0.08);
    padding: 80px 40px 80px 30px;
    transition: all 0.4s ease-in-out;
    z-index: 9;
    @media (max-width: $breakpoint_tablet) {
        left: -100%;
        &.active {
            left: 0;
        }
    }
    .ps-sidebar-root {
        width: 100%;
        border: none;
        .ps-sidebar-container {
            background-color: transparent;
            .ps-submenu-expand-icon {
                background: url('../../assets/images/arrow.svg') center;
                width: 15px;
                height: 8px;
                &.ps-open {
                    filter: brightness(2);
                }
                & > span { display: none;}
            }
            .ps-menu-icon {
                width: 20px;
                min-width: 20px;
                height: auto;
                & > a { margin-bottom: 0 !important;}
                &.ps-open {
                    filter: brightness(2);
                }
            }
            .ps-menu-root {
                ul {
                    li {
                        a {
                            padding: 0;
                            color: #878791;
                            height: 18px;
                            margin-bottom: 30px;
                            &:hover { background-color: transparent; color: #F9F9FC;}
                            &.active {
                                color: #00FFE2;
                            }
                        }
                        &.ps-submenu-root {
                            & > a { transition: all 0.2s ease-in-out;}
                            &.ps-open {
                                margin-bottom: 30px;
                                & > a {
                                    margin-bottom: 20px;
                                    color: #F9F9FC;
                                }
                            }
                        }
                    }
                }
            }
            .ps-submenu-content {
                background-color: transparent;
                padding-left: 10px;
                ul {
                    border-left: 1px solid #878791;
                    li {
                        a {
                            height: 42px;
                            margin-bottom: 0;
                            padding-left: 25px;
                            position: relative;
                            &:hover,&.active {
                                &::before {
                                    opacity: 1;
                                    background: url('../../assets/images/active_inner_hover.svg') center;
                                }
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                left: -5px;
                                top: 0;
                                width: 10px;
                                height: 42px;
                                background: url('../../assets/images/inner_hover.svg') center;
                                // background-size: contain;
                                opacity: 0;
                                transition: all 0.2s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
}
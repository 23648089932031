@import "../../assets/styles/variables.scss";
.login_wrap {
    padding: 15px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(58,58,180,1) 0%, rgba(138,29,253,1) 50%, rgba(69,128,252,1) 100%);

    section {
        background-color: #3F3F4E;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        padding: 30px;
        border-radius: 16px;
        text-align: center;
        h1 {
            margin: 0 0 30px;
            color: #F9F9FC;
            font-weight: bold;
            font-size: 36px;
        }
        img {
            margin-bottom: 20px;
            max-width: 70px;
        }
        ul {
            li { margin-bottom: 20px;}
        }
    }
}
.password {
    position: relative;
    input {
        padding-right: 55px;
    }
    img {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 13px;
        max-width: 25px !important;
        margin-bottom: 0 !important;
    }
}
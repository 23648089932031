@import "../../assets/styles/variables.scss";
.bg_black_layer {
    background-color: #000;
    &.visible {
        .contact_bg_wrapper {opacity: 1;}
        & + .footer_wrap {
            opacity: 1;
        }
    }
}
.contact_bg_wrapper {
    height: 100vh;
    width: 100%;
    position: relative;
    background: url('../../assets/images/contact_bg.jpg') top center;
    background-size: cover;
    @media (max-width: $breakpoint_mobile_landscape) {
        background: url('../../assets/images/contact_bg-mobile.jpg') top center;
    background-size: cover;
    }
    opacity: 0;
    transition: opacity 3s;
    .contact_inner_wrapper {
        position: absolute;
        left: 0;
        top: 50%;
        padding-top: 250px;
        transform: translateY(-50%);
        @media (max-width: $breakpoint_tablet) {
            padding-top: 0;
        }
        ul {
            display: flex;
            flex-direction: column;
            li {
                .request_modal { margin-top: 20px;}
                &:nth-child(2n) {
                    a {
                        width: 583px;
                        @media (max-width: $breakpoint_tablet) {
                            width: 350px;
                        }
                    }
                }
            }
        }
    }
}
.contact_main_btns {
    display: flex;
    align-items: center;
    gap: 16px;
    background: url('../../assets/images/wood_bg.svg') center right no-repeat;
    background-size: cover;
    width: 434px;
    height: 79px;
    font-size: 24px;
    text-transform: uppercase;
    color: #F9F9FC;
    font-family: "Herculanum LT Std";
    padding: 0 0 0 50px;
    outline: none !important;
    border: none;
    margin: 20px 0;
    text-align: left;
    transition: all 0.4s ease-in-out;
    transform-origin: left;
    @media (max-width: $breakpoint_tablet) {
        font-size: 18px;
        padding-left: 20px;
        width: 300px;
        margin: 20px 0;
        height: 60px;
    }
    img {
        transition: all 0.4s ease-in-out;
        @media (max-width: $breakpoint_tablet) {
            max-width: 20px;
        }
    }
    &:hover {
        transform: scale(1.05);
        img {
            transform: scaleX(-1);
        }
    }
}
// .youtube_channel_wrap {
//     @media (max-width: 1600px) {
//         zoom: 0.8
//     }
//     padding: 120px 0 0 12px;
//     @media (max-width: $breakpoint_tablet) {
//         padding: 90px 0 0;
//     }
// }
.youtube_channel {
    display: flex;
    align-items: center;
    gap: 40px;
    zoom: 0.8;
    padding-left: 15px;
    @media (max-width: $breakpoint_tablet) {
        gap: 20px
    }
    & > img{
        width: 150px;
        height: 150px;
        border: 2px solid #00FFE2;
        border-radius: 100%;
        @media (max-width: $breakpoint_tablet) {
            width: 100px;
            height: 100px;
        }
    }
    h3 {
        font-size: 30px;
        margin: 0 0 0;
        @media (max-width: $breakpoint_tablet) {
            font-size: 20px;
        } 
    }
    section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    a {
        zoom: 0.7;
        display: flex;
        align-items: center;
        margin-top: 0;
        img {
            width: 50px;
            margin-right: 10px;
            @media (max-width: $breakpoint_tablet) {
                width: 20px;
            }
        }
    }
}
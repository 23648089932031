@import "../../assets/styles/variables.scss";
.schedule_wrap {
    display: flex;
    justify-content: space-between;
    text-align: center;
    @media (max-width: $breakpoint_tablet) {
        gap: 20px;
    }
    @media (max-width: $breakpoint_mobile_landscape) {
        flex-direction: column;
        gap: 0;
    }
    h2 {
        font-size: 48px;
        margin-bottom: 33px;
        @media (max-width: $breakpoint_tablet) {
            font-size: 32px;
            margin-bottom: 15px;
        }
    }
    .calander_wrap {
        max-width: 482px;
        width: 100%;
        @media (max-width: $breakpoint_tablet) {
            margin-bottom: 30px;
        }
        .request_modal { margin-top: 22px;}
        .react-calendar {
            width: 100%;
            -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
            background-color: rgba(0,0,0,0.1);
            border-color: #F9F9FC;
            border-radius: 30px;
            .react-calendar__navigation {
                border-bottom: 1px solid #F9F9FC;
                height: 105px;
                padding: 3px 35px 0;
                position: relative;
                margin-bottom: 0;
                @media (max-width: $breakpoint_tablet) {
                    height: 75px;
                    padding: 3px 20px 0;
                }
                button {
                    &:disabled {
                        background-color: transparent !important;
                    }
                    font-family: "Poppins", sans-serif;
                    font-size: 24px;
                    color: #F9F9FC;
                    text-transform: uppercase;
                    font-weight: bold;
                    @media (max-width: $breakpoint_tablet) {
                        font-weight: 400;
                        font-size: 18px;
                    }
                    &.react-calendar__navigation__label {
                        text-align: left;
                    }
                    &:focus,
                    &:hover { background-color: transparent;}
                    &.react-calendar__navigation__arrow {
                        font-size: 64px;
                        font-weight: 300;
                        position: absolute;
                        right: 25px;
                        top: 50%;
                        transform: translateY(-50%);
                        @media (max-width: $breakpoint_tablet) {
                            font-size: 45px;
                            right: 5px;
                        }
                        &.react-calendar__navigation__prev-button {
                            right: 100px;
                            @media (max-width: $breakpoint_tablet) {
                                right: 50px;
                            }
                        }
                        &.react-calendar__navigation__next2-button,
                        &.react-calendar__navigation__prev2-button { display: none;}
                    }
                }
            }
            .react-calendar__viewContainer {
                padding: 32px 35px 39px;
                @media (max-width: $breakpoint_tablet) {
                    padding: 10px 15px 10px;
                }
            }
            .react-calendar__month-view__weekdays__weekday {
                font-size: 20px;
                @media (max-width: $breakpoint_tablet) {
                    font-size: 12px;
                }
            }
            .react-calendar__year-view,
            .react-calendar__decade-view__years,
            .react-calendar__century-view__decades {
                button {
                    font-size: 18px !important;
                    @media (max-width: $breakpoint_tablet) {
                        font-size: 15px !important;
                    }
                }
            }
            .react-calendar__month-view__days,
            .react-calendar__year-view,
            .react-calendar__decade-view__years,
            .react-calendar__century-view__decades {
                button {
                    font-size: 24px;
                    font-weight: bold;
                    color: #F9F9FC;
                    height: 48px;
                    position: relative;
                    background: transparent !important;
                    @media (max-width: $breakpoint_tablet) {
                        font-size: 13px;
                        height: auto;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 5px;
                        top: 0;
                        width: 48px;
                        height: 48px;
                        border: 1px solid #00FFE2;
                        border-radius: 100%;
                        opacity: 0;
                        @media (max-width: $breakpoint_tablet) {
                            left: 50%;
                            transform: translateX(-51%);
                            top: 1px;
                            width: 35px;
                            height: 35px;
                        }
                    }
                    &.react-calendar__tile--active {
                        background-color: transparent;
                        &::before { opacity: 1;}
                        color: #00FFE2;
                    }
                    &.react-calendar__tile--now { background-color: transparent; color: #00FFE2;}
                    &:hover,&:focus {
                        background-color: transparent;
                    }
                    &.react-calendar__month-view__days__day--neighboringMonth {
                        opacity: 0.24;
                    }
                }
            }
        }
    }
    .available_time {
        max-width: 404px;
        width: 100%;
        .slots_wrap {
            margin-bottom: 48px;
            @media (max-width: $breakpoint_tablet) {
                margin-bottom: 15px;
            }
            ul {
                margin: 0 -25px;
                max-height: 230px;
                overflow: auto;
                @media (max-width: $breakpoint_tablet) {
                    margin: 0 -10px;
                }
                li {
                    padding: 0 25px;
                    width: 50%;
                    margin-bottom: 20px;
                    @media (max-width: $breakpoint_tablet) {
                        padding: 0 10px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
        .schedule_form {
            ul {
                li {
                    margin-bottom: 23px;
                    &:last-child {margin-bottom: 0;}
                    .request_modal { margin-top: 0;}
                }
            }
        }
    }
}
.slots {
    margin-bottom: 0;
    position: relative;
    input {
        position: absolute;
        left: 0;
        opacity: 0;
        &:checked + span {
            border-color: #00FFE2;
            color: #00FFE2;
        }
    }
    span {
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        border: 1px solid #F9F9FC;
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        background-color: rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 165px;
        height: 63px;
        border-radius: 20px;
        font-family: 'Poppins', sans-serif;
        @media (max-width: $breakpoint_tablet) {
            width: 125px;
            height: 50px;
            border-radius: 15px;
        }
    }
}



@import "../../assets/styles/variables.scss";
.bg_black_layer_gift {
    background-color: #000;
    &.visible {
        .gift_bg_wrapper {opacity: 1;}
        & + .footer_wrap {
            opacity: 1;
        }
    }
}
.gift_bg_wrapper {
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 3s;
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .gift_links {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding-top: 100px;
        @media (max-width: $breakpoint_tablet) {
            padding-top: 0;
            transform: none;
            top: 80px;
        }
        ul {
            li {
                margin: 23px 0;
                @media (max-width: $breakpoint_tablet) {
                    margin: 15px 0;
                }
                button {
                    display: flex;
                    align-items: center;
                    // background: url('../../assets/images/gift_link.png') right;
                    height: 79px;
                    font-size: 24px;
                    padding-left: 50px;
                    // min-width: 324px;
                    min-width: 324px;
                    border: none;
                    background: transparent;
                    color: #F9F9FC;
                    position: relative;
                    -webkit-backdrop-filter: blur(10px);
                    backdrop-filter: blur(10px);
                    clip-path: polygon(80% 0%, 100% 50%, 80% 100%, 0 100%, 0 0);
                    transition: all 0.4s ease-in-out;
                    transform-origin: left;
                    @media (max-width: $breakpoint_tablet) {
                        height: 60px;
                        font-size: 18px;
                        padding-left: 20px;
                        min-width: 220px;
                    }
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    .gift_content {
        width: 100%;
        max-width: 680px;
        margin: 0 auto;
        padding-bottom: 92px;
        text-align: center;
        z-index: 2;
        .request_modal { margin-top: 24px;}
    }
}

@import "../../assets/styles/variables.scss";
.footer_wrap {
    position: fixed;
    left: 0;
    bottom: 47px;
    width: 100%;
    @media (max-width: $breakpoint_tablet) {
        bottom: 15px;
        zoom: 0.6;
    }
    .container {
        display: flex;
        justify-content: flex-end;
        a {
            display: inline-block;
            transition: all 0.2s ease-in-out;
            &:hover { transform: scale(0.95);}
        }
    }
}
@import "../../../assets/styles/variables.scss";
.banner_wrapper{
    height: 100vh;
    width: 100%;
    position: relative;
    opacity: 0;
    transition: opacity 3s;
    &.visible {
        opacity: 1;
    }
    & + .footer_wrap { display: none;}
    section {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 130px 20px 0;
        @media (max-width: $breakpoint_tablet) {
            zoom: 0.75
        }
        h1 {
            gap: 12px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            font-size: 24px;
            @media (max-width: $breakpoint_tablet) {
                max-width: 85%;
            }
        }
        button {
            padding: 0;
            background-color: transparent;
            border: none;
            width: 36px;
            height: 36px;
            position: relative;
            // @media (max-width: $breakpoint_mobile_landscape) {
            //     position: absolute;
            //     right: 15px;
            //     top: 85px;
            // }
            img {
                position: absolute;
                left: 0;
                top: 0;
                max-width: 30px;
            }
        }
    }
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@import "../../assets/styles/variables.scss";
.header_wrap {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    border-bottom: 1px solid #fff;
    padding: 13px 0;
    z-index: 999;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    @media (max-width: $breakpoint_tablet) {
        padding: 20px 0;
    }
    .navigation_wrap {
        margin: 0 auto;
        @media (max-width: $breakpoint_tablet) {
            -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
            background-color: rgba(0,0,0,0.8);
            display: none;
            position: absolute;
            right: 0;
            top: 40px;
            width: 100%;
            padding: 50px;
            &.active {
                display: block;
            }
        }
        ul {
            gap: 35px;
            justify-content: space-between;
            @media (max-width: $breakpoint_tablet) {
                gap: 0;
                align-items: flex-start;
                flex-direction: column;
            }
            li {
                position: relative;
                a {
                    font-family: 'Luminari';
                    line-height: 24px;
                    transition: all 0.4s ease-in-out;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 24px;
                    &.active,&:hover {
                        color: #00FFE2 !important;
                    }
                }
            }
        }
    }
}
.menu_btn {
    display: none;
    margin-left: auto;
    width: 36px;
    height: 20px;
    position: relative;
    border: none;
    margin-right: 7px;
    background: none;
    &.sidebar_button {
        position: fixed;
        background: #3F3F4E;
        left: 15px;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        top: 15px;
        z-index: 10;
        span {
            width: 30px;
            @media (max-width: $breakpoint_tablet) {
                right: 9px;
                top: 14px;
            }
        }
    }
    &.active {
        span {
            background: transparent;
            &::before {
                width: 100%;
                transform: rotate(45deg);
                top: 10px;
            }
            &::after {
                width: 100%;
                transform: rotate(-45deg);
                top: 10px;
            }
        }
    }
    span {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 2px;
        background: #F9F9FC;
        transition: all 0.4s ease-in-out;
        &::before {
            content: '';
            position: absolute;
            width: calc(100% - 6px);
            height: 2px;
            top: 9px;
            right: 0;
            background-color: #F9F9FC;
            transition: all 0.4s ease-in-out;
        }
        &::after {
            content: '';
            position: absolute;
            width: calc(100% - 13px);
            height: 2px;
            top: 18px;
            right: 0;
            background-color: #F9F9FC;
            transition: all 0.4s ease-in-out;
        }
    }
    @media (max-width: $breakpoint_tablet) {
        display: block;
    }
}
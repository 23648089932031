@import "./variables.scss";
@font-face {
  font-family: 'Herculanum LT Std';
  src: url('../fonts/HerculanumLTStd.woff2') format('woff2'),
      url('../fonts/HerculanumLTStd.woff') format('woff'),
      url('../fonts/HerculanumLTStd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Luminari';
  src: url('../fonts/Luminari-Regular.woff2') format('woff2'),
      url('../fonts/Luminari-Regular.woff') format('woff'),
      url('../fonts/Luminari-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.userlayout {
  font-family: "Herculanum LT Std";
  color: #F9F9FC;
  h1,h2,h3,h4,h5,h6,input,button,textarea,input,textarea,select {
    font-family: "Herculanum LT Std";
    color: #F9F9FC;
  }

  .Toastify__toast-body { text-align: left; color: #fff; align-items: flex-start !important; font-family: "Herculanum LT Std"; }
  .Toastify__toast-icon { margin-top: 3px;}
  .Toastify__toast-container .Toastify__toast {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: inset 0 0 30px rgba(249, 249, 252, 0.5);
    backdrop-filter: blur(8px);
  }
}

@import "../../assets/styles/variables.scss";
.main_bg_wrapper {
    height: 100vh;
    width: 100%;
    position: relative;
    background: url('../../assets/images/main-bg.jpg') center;
    background-size: cover;
    @media (max-width: $breakpoint_mobile_landscape) {
        background: url('../../assets/images/main-bg-mobile.jpg') center;
    background-size: cover;
    }
    opacity: 0;
    transition: opacity 3s;
    &.visible {
        opacity: 1;
        & + .footer_wrap {
            opacity: 1;
        }
    }
    .inner_wrapper {
        position: absolute;
        left: 0;
        top: 50%;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        transform: translateY(-50%);
        @media (max-width: $breakpoint_tablet) {
            padding-top: 0;
        }
    }
}
.main_btns {
    background: url('../../assets/images/arrow_shape.svg') center right no-repeat;
    background-size: contain;
    width: 434px;
    height: 79px;
    font-size: 24px;
    text-transform: uppercase;
    color: #F9F9FC;
    font-family: "Herculanum LT Std";
    padding: 0 0 0 50px;
    outline: none !important;
    border: none;
    margin: 45px 0;
    text-align: left;
    transition: all 0.4s ease-in-out;
    transform-origin: left;
    @media (max-width: $breakpoint_tablet) {
        width: 330px;
        height: 60px;
        font-size: 20px;
        margin: 35px 0;
    }
    &:hover {
        transform: scale(1.05);
    }
}

@import "../../../../assets/styles/variables.scss";
.table_layout_wrapper {
    @media (max-width: $breakpoint_tablet) {
        padding-top: 30px;
    }
    .table_title {
        display: flex;
        flex-direction: column;
        h2 {
            margin: 0 0 40px;
            font-size: 36px;
            font-weight: bold;
            @media (max-width: $breakpoint_tablet) {
                margin: 0 0 20px;
                font-size: 26px;
            }
        }
        .table_search {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            @media (max-width: $breakpoint_mobile_landscape) {
                flex-direction: column;
            }
            form {
                @media (max-width: $breakpoint_tablet) {
                    width: 100%;
                }
                ul {
                    gap: 20px;
                    @media (max-width: $breakpoint_mobile_landscape) {
                        margin-bottom: 30px;
                        gap: 10px;
                        justify-content: center;
                    }
                    @media (max-width: $breakpoint_mobile_landscape) {
                        flex-direction: column;
                    }
                    li {
                        .input_with_calendar {
                            & > img {
                                top: 12px;
                            }
                        }
                        select,input {
                            width: 300px;
                            border-color: #DBDBDB;
                            padding-left: 20px;
                            background-color: #F9F9FC;
                            box-shadow: 6px 0px 20px rgba(0,0,0,0.08);
                            border-radius: 8px;
                            padding-right: 45px;
                            @media (max-width: $breakpoint_tablet) {
                                width: 250px;
                            }
                            @media (max-width: $breakpoint_mobile_landscape) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .pagination_wrap {
                display: flex;
                align-items: center;
                gap: 30px;
                h6 {
                    margin: 0;
                    font-size: 16px;
                    white-space: nowrap;
                }
                ul {
                    gap: 6px;
                    li {
                        button {
                            width: 49px;
                            height: 49px;
                            border-radius: 6px;
                            border: 1px solid #C2C2C2;
                            box-shadow: 8px 8px 16px rgba(0,0,0,0.08);
                            background-color: #F9F9FC;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all 0.4s ease-in-out;
                            &:hover {
                                background-color: rgba(25,25,29,0.2);
                            }
                            &.active { background-color: #19191D; color: #FFFFFF;}
                        }
                    }
                }
            }
        }
    }
    &.mini_table {
        .table_search {
            max-width: 739px;
        }
        .table_layout {
            max-width: 739px;
            @media (max-width: $breakpoint_tablet) {
                .table_layout_responsive {
                    table {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
    .table_layout {
        box-shadow: 6px 0px 20px rgba(0,0,0,0.08);
        border-radius: 16px;
        overflow: hidden;
        .table_layout_responsive {
            @media (max-width: $breakpoint_tablet) {
                overflow: auto;
            }
            &.bigtable {
                overflow: auto;
                table {
                    width: 1620px;
                    tr {
                        th {
                            white-space: nowrap;
                        }
                    }
                }
            }
            table {
                @media (max-width: $breakpoint_tablet) {
                    max-width: 1200px;
                    width: 1200px;
                }
                .actions {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                    button {
                        font-weight: bold;
                        border: none;
                        background-color: transparent;
                        padding: 0;
                        border-bottom: 1px solid;
                        transition: all 0.2s ease-in-out;
                        &.reject {color: #F55B37;}
                        &:hover {
                            transform: scale(0.95);
                        }
                    }
                    select {
                        padding-left: 5px;
                        font-size: 14px;
                        height: 40px;
                        padding-right: 0;
                    }
                    img {
                        cursor: pointer; max-width: 22px; transition: all 0.2s ease-in-out;
                        &:hover {transform: scale(0.9);}
                    }
                }
                tr {
                    th,td {
                        padding: 20px 35px;
                        vertical-align: middle;
                        border: 1px solid #D1D1D1;
                        .innerLink {
                            color: #19191D;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                thead {
                    tr {
                        background-color: #19191D;
                        th {
                            color: #F9F9FC;
                            text-align: left;
                            font-size: 16px;
                            font-weight: bold;
                            &:last-child { width: 205px;}
                        }
                    }
                }
                tbody {
                    tr {
                        &:nth-child(even) {
                            background-color: #EFEFEF;
                        }
                    }
                }
            }
        }
    }
}
.set-availability {
    margin: 40px 0 80px;
    padding: 20px;
    border-radius: 16px;
    border: 1px solid #D1D1D1;
    box-shadow: 6px 0 20px rgba(0,0,0,0.08);
    @media (max-width: $breakpoint_tablet) {
        margin-bottom: 0;
    }
    h2 {
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 36px;
        @media (max-width: $breakpoint_tablet) {
            font-size: 26px;
        }
    }
    ul {
        margin: 0 -15px;
        @media (max-width: $breakpoint_tablet) {
            flex-direction: column;
        }
        li {
            width: 50%;
            padding: 0 15px;
            margin-bottom: 20px;
            @media (max-width: $breakpoint_tablet) {
                width: 100%;
            }
            &:last-child {margin-bottom: 0;}
            input,select {
                border: 1px solid #D1D1D1;
                height: 63px;
                border-radius: 8px;
                background-color: #F9F9FC;
            }
        }
    }
}
.slot_wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.slot {
    position: relative;
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px 0 12px;
    font-size: 12px;
    color: #F9F9FC;
    background-color: #19191D;
    border-radius: 4px;
    height: 37px;
    img {
        cursor: pointer;
        position: absolute;
        right: -4px;
        top: -4px;
    }
}
.shipping_frequency {
    padding: 20px 20px 35px;
    border-radius: 16px;
    margin-bottom: 55px;
    border: 1px solid #D1D1D1;
    box-shadow: 16px 16px 32px rgba(0,0,0,0.08);
    p {margin-bottom: 29px;}
    form {
        display: flex;
        gap: 42px;
        align-items: flex-start;
        @media (max-width: $breakpoint_tablet) {
            flex-direction: column;
            gap: 15px;
        }
        input {
            border: 1px solid #D1D1D1;
            height: 63px;
            border-radius: 8px;
            background-color: #F9F9FC;
        }
    }
}
.payment_wrapper {
    @media (max-width: $breakpoint_tablet) {
        margin-top: 40px;
    }
    section {
        max-width: 746px;
        width: 100%;
        border: 1px solid #D1D1D1;
        box-shadow: 6px 0px 20px rgba(0,0,0,0.08);
        padding: 20px 30px;
        border-radius: 16px;
        @media (max-width: $breakpoint_tablet) {
            padding: 20px;
        }
        h2 {
            margin-bottom: 40px;
            font-size: 32px;
            font-weight: bold;
            @media (max-width: $breakpoint_tablet) {
                font-size: 24px;
            }
        }
        ul {
            li {
                &:last-child { margin-bottom: 20px;}
                margin-bottom: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media (max-width: $breakpoint_tablet) {
                    flex-wrap: wrap;
                }
                figure {
                    margin: 0;
                    max-width: 182px;
                    width: 100%;
                    @media (max-width: $breakpoint_tablet) {
                        margin-bottom: 10px;
                    }
                }
                p {
                    max-width: 230px;
                    margin-bottom: 0;
                    width: 100%;
                    span {
                        display: block;
                    }
                }
                & > img {
                    cursor: pointer;
                    max-width: 22px;
                    transition: all 0.2s ease-in-out;
                    &:hover {
                        transform: scale(0.9);
                    }
                }
            }
        }
    }
}
.input_field {
    width: 100%;
    border: 1px solid #D1D1D1;
    min-height: 63px;
    border-radius: 8px;
    background-color: #F9F9FC;
    padding: 11px 50px 11px 20px;
    position: relative;
    & > img {
        position: absolute;
        right: 18px;
        top: 20px;
        cursor: pointer;
    }
}
.input_with_calendar {
    position: relative;
    & > img {
        position: absolute;
        right: 18px;
        top: 20px;
        cursor: pointer;
        &.searchicon { top: 14px !important;}
    }
}
.multi_select {
    &.disabled {
        opacity: 0.6;
    }
    input {
        height: 34px !important;
    }
    & > div {
        & > div {
            border-radius: 8px;
            background-color: #F9F9FC;
            box-shadow: 6px 0px 20px rgba(0,0,0,0.08);
            border-color: #D1D1D1;
            min-height: 63px;
        }
    }
}
@import "../../assets/styles/variables.scss";
.book_wrapper{
    height: 100vh;
    width: 100%;
    position: relative;
    opacity: 0;
    transition: opacity 3s;
    background: url('../../assets/images/book_bg.jpg') center;
    background-size: cover;
    @media (max-width: $breakpoint_mobile_landscape) {
        background: url('../../assets/images/book_bg_mobile.jpg') top center;
        background-size: cover;
    }
    &.visible {
        opacity: 1;
    }
    & + .footer_wrap { display: none;}
    .book_content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 130px 30px 0;
        @media (max-width: $breakpoint_tablet) {
            padding: 90px 10px 0;
        }
        &.active {
            section {
                opacity: 1;
                visibility: visible;
                .request_modal{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        section {
            background: url('../../assets/images/book-content.jpg') center;
            background-size: 100% 100%;
            padding: 83px 72px;
            max-width: 688px;
            min-height: 516px;
            text-align: center;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            opacity: 0;
            visibility: hidden;
            transition: opacity 3s;
            @media (max-width: $breakpoint_mobile_landscape) {
                padding: 45px 27px;
                max-width: 688px;
                min-height: 360px;
            }
            h2 {
                margin: 0 0 24px;
                line-height: 33px;
                @media (max-width: $breakpoint_mobile_landscape) {
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 15px;
                }
            }
            .request_modal {
                margin-top: 0;
                display: flex;
                align-items: center;
                gap: 12px;
                transition: opacity 3s;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.box_wrap {
    position: absolute;
    bottom: 30px;
    right: 30px;
    background-color: #F9F9FC;
    font-size: 24px;
    color: #19191D;
    padding: 19px 40px;
    border-radius: 8px;
    @media (max-width: $breakpoint_tablet) {
        bottom: 10px;
        right: 10px;
    }
    @media (max-width: $breakpoint_mobile_landscape) {
        padding: 14px 30px;
        font-size: 14px;
    }
}